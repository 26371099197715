.topBarRoot {
  margin-top: -8px;
  margin-left: -8px;
  margin-right: -8px;
  padding-bottom: 10px;
}

.topBarButton {
  width: 50px;
  height: 50px;
  margin-top: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: fixed !important;
  z-index: 999;
}

.topBarTitle {
  margin-left: 5px !important;
  font-size: 32px !important;
}

.topBarToolbarDesktop {
  margin-left: 200px;
  margin-top: 70px;
}

.topBarToolbarMobile {
  margin-top: 0px;
  align-items: start !important;
  padding-top: 5px !important;
}

.topBarColors {
  color: #eee !important;
  background-color: #fff !important;
}

.topBarApp {
  height: 150px;
}

.topBarTextDesktop {
  font: bold 40px 'Arial';
  width: 505px;
  height: 75px;
}

.topBarTextMobile {
  margin-top: 40px;
  font: bold 35px 'Arial';
  width: 260px;
  height: 100px;
  text-align: right;
  position: absolute;
  right: 10px;
}

.topBarRoot text {
  fill: white;
  stroke: black;
  stroke-width: 2px;
  stroke-linejoin: round;
  font-weight: 700;
}

$white: rgba(255,255,255,1);
$transparent: rgba(255,255,255,0);
$black: rgb(0,0,0);

div.articleCards {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

a.articleCard--link {
  margin: 20px 0;
  max-width: 800px;
}

div.articleCard {
  box-shadow: 1px 1px 12px 3px rgba(0, 0, 0, 0.2), 0px 0px 3px 0px rgba(0, 0, 0, 0.5) !important;
  min-height: 150px;
  max-width: 800px;
  position: relative;

  background-repeat: no-repeat;
  background-size: 85%;
  background-position: 150% center;

  div.articleCard--infoBox {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px;
    background-image: linear-gradient(to right, $white, $white, $white, $white, $transparent);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      margin: 10px;
      color: $black;
      padding-right: 30px;
    }

    span {
      margin: 5px;
      padding-right: 20px;
    }
  }
}

@media (max-width: 600px) {
  div.articleCard {
    height: 200px;
    
    background-size: 125%;
    background-position: center center;

    div.articleCard--infoBox {
      width: 100%;
      background-image: linear-gradient(to bottom right, $white, $white, $transparent, $transparent);

      h3 {
        padding-right: 50%;
      }

      span {
        display: none;
      }
    }
  }
}
.menuButton {
  display: flex;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 5px;
  width: 200px;
  display: flex;
  text-align: left;
  color: #eee !important;
}

.menuIcon {
  margin-right: 20px;
  margin-left: 10px;
  width: 30px !important;
  color: #eee !important;
}

.menuBox {
  margin-top: 20px;
  width: 200px;
}

.menuLabel {
  font-size: 16px;
}

.menuPaper {
  background-color: #222 !important;
  color: #eee !important;
  position: relative;
}

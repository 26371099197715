.contentTransition-appear {
  opacity: 0;
  transform: translateX(-100px);
}

.contentTransition-appear.contentTransition-appear-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all .4s ease-in-out;
}

.articleView {
  padding: 10px;
}

.homeRoot {
  padding: 10px;
}

.homeCard {
  max-width: 350px;
  width: 350px;
  float: left;
  margin: 10px;
  padding-top: 10px;
  height: 300px;
  box-shadow: 1px 1px 12px 3px rgba(0, 0, 0, 0.2), 0px 0px 3px 0px rgba(0, 0, 0, 0.5) !important;
}

.withDrawer {
  margin-left: 200px;
}

blockquote {
  padding-left: 2em;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 10px;
  background-image: url("../Statics/Images/important.png");
  background-repeat: no-repeat;
}

blockquote p {
  margin-top: 2px !important;
}

.copyright {
  font-size: 12px;
  text-align: right;
  color: #888;
}

.imageContent {
  max-width: 100%;
  height: auto;
}

.contentBottomIcon {
  position: fixed !important;
  bottom: 10px;
}

.contentBottomIconUp {
  background-color: rgba(50, 185, 0, 0.35) !important;
  right: 10px;
}

@media only screen and (max-width: 768px) {
  .imageContent {
    width: 100%;
    height: 100%;
  }

  .homeCard {
    width: 100%;
  }

  .articleView {
    margin-right: 15px;
  }
}

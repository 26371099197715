.footerLittleRoot {
  position: absolute;
  bottom: 0;
  font-size: 12px !important;
  text-align: center;
  width: 150px;
}

.footerLittleIconFontSizeSmall {
  font-size: 14px !important;
}

.footerLittleRoot > ul {
  list-style-type: none;
  padding-left: 10px;
  padding-top: 10px;
}

.footerLittleRoot > ul > li {
  padding-top: 5px;
  color: #999 !important;
}

.footerLittleRoot > ul > li > a {
  color: #999 !important;
}

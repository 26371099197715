.commentQuote {
  padding: 0.5em;
  border-left: 5px solid #999;
  background-color: #eee;
  margin-bottom: 2px;
}

.commentQuote p {
  color: #555;
  font-size: 12pt;
  line-height: 1.4;
  font-family: 'PT Serif', Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;
  padding: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.commentsTime {
  font-size: 10pt;
}

.commentInputFormBox {

}

.commentInputFormBox input {

}

.commentInputElementRoot {
  margin-top: 5px !important;
}

.commentInputFootInfo {
  font-size: 12px;
  text-align: right;
  color: #888;
}

.commentInputFootInfo a{
  color: #888;
  text-decoration: underline;
}

.grecaptcha-badge {
    visibility: hidden;
}

span.authorSuffix {
  color: rgb(200, 50, 50);
}

div.authorLight {
  background-color: rgb(250, 235, 225);
}
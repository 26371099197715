.avatarRoot {
  height: 125px;
  width: 200px;
}

.avatarImg {
  border-radius: 50%;
  height: 125px;
  width: 125px;
  margin-left: 35px;
  margin-top: 10px;
}
